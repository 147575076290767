import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40c38eae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-events-container__item-header" }
const _hoisted_2 = { class: "c-events-container__item-type" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-events-container__item-info" }
const _hoisted_5 = { class: "c-events-container__item-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScoreCircular = _resolveComponent("ScoreCircular")!

  return (_openBlock(), _createElementBlock("div", {
    class: "c-events-container__item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCardClick && _ctx.handleCardClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.iconEventUrl,
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("p", null, _toDisplayString(_ctx.event.name), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ScoreCircular, {
            class: "c-card__circular-score",
            score: _ctx.efficiency?.value,
            color: _ctx.scoreHelper.scoreColor(_ctx.efficiency?.value)
          }, null, 8, ["score", "color"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildInfoItems(), (data, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "c-events-container__item-data"
          }, [
            _createElementVNode("p", null, _toDisplayString(data.value), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t(`wearables.nutritionTab.${data.key}`)), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}