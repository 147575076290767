import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c9ac340c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "testType" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "testType" }
const _hoisted_7 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('wearables.nutritionTab.add-planification'),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.accept'),
        class: "p-button p-component p-button-primary p-button-medium",
        loading: _ctx.submitting,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.nutritionTab.select-week-duration-and-start-date')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('wearables.nutritionTab.number-of-weeks')), 1),
            _createVNode(_component_Dropdown, {
              id: "menuNutritionalPlanType",
              modelValue: _ctx.menuDayFormDto.weeks,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuDayFormDto.weeks) = $event)),
              options: _ctx.weeksOptions,
              "option-label": "label",
              "option-value": "value",
              class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validatedMenuDayForm.weeks.valid,
            })
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.showValidation && !_ctx.validatedMenuDayForm.weeks.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validatedMenuDayForm.weeks.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('wearables.nutritionTab.start-date')), 1),
            _createVNode(_component_Calendar, {
              id: "startDate",
              modelValue: _ctx.menuDayFormDto.startDate,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menuDayFormDto.startDate) = $event)),
              "year-navigator": true,
              "year-range": `${_ctx.minRangeDate}:${_ctx.maxRangeDate}`,
              class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validatedMenuDayForm.startDate.valid,
            })
            }, null, 8, ["modelValue", "year-range", "class"]),
            (_ctx.showValidation && !_ctx.validatedMenuDayForm.startDate.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t(`${_ctx.validatedMenuDayForm.startDate.message}`)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}